import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
import 'popper.js';
import 'bootstrap';
import './assets/app.scss';
import 'hot-formula-parser';

createApp(App).use(router).mount('#app')
