<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Batching Plant And Transit Mixer</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label class="col-sm-4 control-label" for="customerName"
                          >Customer Name</label
                        >
                        <input
                          id="customerName"
                          name="customerName"
                          type="text"
                          placeholder="Awanish"
                          class="form-control col-sm-4"
                          required=""
                          v-model="customerName"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          customerNameError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label class="col-sm-4 control-label" for="customerType"
                          >Customer Type</label
                        >
                        <select
                          id="customerType"
                          name="customerType"
                          class="form-control"
                        >
                          <option
                            :value="option.value"
                            v-for="(option, index) in customerTypes"
                            :key="index"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="projectCategory"
                          >Project Category</label
                        >
                        <select
                          id="projectCategory"
                          name="projectCategory"
                          class="form-control"
                          v-model="firstOption"
                        >
                          <option disabled>Select Category</option>
                          <option
                            v-for="(item, index) in projectSubCategories"
                            :key="index"
                          >
                            {{ index }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="projectSubCategory"
                          >Project Sub Category</label
                        >
                        <select
                          id="projectSubCategory"
                          name="projectSubCategory"
                          class="form-control"
                          v-model="secondOption"
                          v-if="firstOption"
                        >
                          <option disabled>Select Subcategory</option>
                          <option
                            :value="option.value"
                            v-for="(option, index) in projectSubCategories[
                              firstOption
                            ]"
                            :key="index"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label class="col-sm-4 control-label" for="conReq"
                          >Concrete Requirement (m³)</label
                        >
                        <input
                          id="conReq"
                          name="conReq"
                          type="text"
                          placeholder="30000"
                          class="form-control input-md"
                          required=""
                          v-model="concreteRequirement"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          concreteRequirementError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="peakConcreteRequirement"
                          >Peak Concrete Requirement (m³/day)</label
                        >
                        <input
                          id="peakConcreteRequirement"
                          name="peakConcreteRequirement"
                          type="text"
                          placeholder="250"
                          class="form-control input-md"
                          required=""
                          v-model="peakConcreteRequirement"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          peakConcreteRequirementError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label class="col-sm-4 control-label" for="traverseTime"
                          >Traverse time for TM- one way (mins)</label
                        >
                        <input
                          id="traverseTime"
                          name="traverseTime"
                          type="text"
                          placeholder="5"
                          class="form-control input-md"
                          v-model="traverseTime"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          traverseTimeError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="avgPlacingTime"
                          >Average placing time (Mins)</label
                        >
                        <input
                          id="avgPlacingTime"
                          name="avgPlacingTime"
                          type="text"
                          placeholder="15"
                          class="form-control input-md"
                          v-model="avgPlacingTime"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          avgPlacingTimeError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="projectDuration"
                          >Project Duration (months)</label
                        >
                        <input
                          id="projectDuration"
                          name="projectDuration"
                          type="text"
                          placeholder="24"
                          class="form-control input-md"
                          v-model="projectDurationInMonths"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          projectDurationInMonthsError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="concretingDuration"
                          >Concreting Duration (months)</label
                        >
                        <input
                          id="concretingDuration"
                          name="concretingDuration"
                          type="text"
                          placeholder="10"
                          class="form-control input-md"
                          v-model="concretingDurationInMonths"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          concretingDurationInMonthsError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="avgWorkingHours"
                          >Average working hrs/day</label
                        >
                        <input
                          id="avgWorkingHours"
                          name="avgWorkingHours"
                          type="text"
                          placeholder="8"
                          class="form-control input-md"
                          v-model="averageWorkingHours"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          averageWorkingHoursError
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-inline">
                        <label
                          class="col-sm-4 control-label"
                          for="workingHoursForPeakConcrete"
                          >Working Hours for peak concrete</label
                        >
                        <input
                          id="workingHoursForPeakConcrete"
                          name="workingHoursForPeakConcrete"
                          type="text"
                          placeholder="10"
                          class="form-control input-md"
                          v-model="workingHrsForPeakConcrete"
                        />
                        <span class="help-block col-sm-4 text-danger">{{
                          workingHrsForPeakConcreteError
                        }}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- <br /><br />
            <button class="btn btn-primary" @click="this.getResults">
              Submit
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Results</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5>Concreting Requirement</h5>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <label class="control-label" for="PerDay"
                ><strong>Per Day</strong></label
              >
            </div>
            <div class="col-md-12">
              <input
                class="control-label"
                disabled
                v-model="perDayRequirement"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <label class="control-label" for="PerMonth"
                ><strong>Per Month</strong></label
              >
            </div>
            <div class="col-md-12">
              <input
                class="control-label"
                disabled
                v-model="perMonthRequirement"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Batching Plant</h5>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <label class="control-label" for="AeverageRequiremnet"
                ><strong>Average Requirement</strong></label
              >
            </div>
            <div class="col-md-12">
              <input
                class="control-label"
                disabled
                v-model="batchingPlantAverageRequirement"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <label class="control-label" for="PeakRequirement"
                ><strong>Peak Requirement</strong></label
              >
            </div>
            <div class="col-md-12">
              <input
                class="control-label"
                disabled
                v-model="batchingPlantPeakRequirement"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Transit Mixer 7m³</h5>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <label class="control-label" for="AeverageRequiremnetTransit"
                ><strong>Average Requirement</strong></label
              >
            </div>
            <div class="col-md-12">
              <input
                class="control-label"
                disabled
                v-model="transitMixerAverageRequirement"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <label class="control-label" for="PeakRequirementTransit"
                ><strong>Peak Requirement</strong></label
              >
            </div>
            <div class="col-md-12">
              <input
                class="control-label"
                disabled
                v-model="transitMixerPeakRequirement"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">Developed By Aadi IT Services</div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "Calculator",
  data() {
    return {
      isHidden: true,
      firstOption: "Select Category",
      secondOption: "Select Subcategory",
      customerName: "",
      customerNameError: "",
      perDayResult: "",
      perMonthResult: "",
      concreteRequirement: "",
      concreteRequirementError: "",
      peakConcreteRequirement: "",
      peakConcreteRequirementError: "",
      traverseTime: "",
      traverseTimeError: "",
      avgPlacingTime: "",
      avgPlacingTimeError: "",
      projectDurationInMonths: "",
      projectDurationInMonthsError: "",
      concretingDurationInMonths: "",
      concretingDurationInMonthsError: "",
      averageWorkingHours: "",
      averageWorkingHoursError: "",
      workingHrsForPeakConcrete: "",
      workingHrsForPeakConcreteError: "",
      batchingPlantAverageRequirementResult: "",
      batchingPlantPeakRequirementResult: "",
      transitMixerAverageRequirementResult: "",
      transitMixerPeakRequirementResult: "",
      averageWorkingDaysPerMonth: "",
      concretingDaysPerMonth: "",
      travelDisachargeTimeInMins: "",
      customerTypes: [
        { value: "1", name: "Individual/Contractor" },
        { value: "2", name: "Hirer" },
        { value: "3", name: "Corporate" },
      ],
      projectCategories: [
        { value: "1", name: "Irrigation" },
        { value: "2", name: "Roads" },
        { value: "3", name: "Bridges" },
        { value: "4", name: "Urban Infrastructures" },
        { value: "5", name: "Railways" },
        { value: "6", name: "Buildings and Factories" },
        { value: "7", name: "Power Projects" },
        { value: "8", name: "Pre-cast" },
      ],
      projectSubCategories: {
        Irrigation: [
          { value: "1", name: "Canal Lining" },
          { value: "2", name: "Aquaducts" },
          { value: "3", name: "Weir" },
          { value: "4", name: "Anicut" },
          { value: "5", name: "Lift Irrigation" },
          { value: "6", name: "Side Drains" },
          { value: "7", name: "Power Projects" },
          { value: "8", name: "Flood Line Canal" },
          { value: "9", name: "Structures (Check Dams)" },
        ],
        Roads: [
          { value: "1", name: "PMGSY" },
          { value: "2", name: "CMGSY" },
          { value: "3", name: "MDR" },
          { value: "4", name: "State and National Highway" },
        ],
        Bridges: [
          { value: "1", name: "Road Over Bridges" },
          { value: "2", name: "Road Under Pass" },
          { value: "3", name: "River Over Bridge" },
        ],
        "Urban Infrastructures": [
          { value: "1", name: "City Roads" },
          { value: "2", name: "Storm Water Drain" },
          { value: "3", name: "Flyovers" },
          { value: "4", name: "Metros" },
          { value: "5", name: "Site Layouting/Drain Works" },
        ],
        Railways: [
          { value: "1", name: "Low Height Subways" },
          { value: "2", name: "Bridges" },
          { value: "3", name: "Stations" },
          { value: "4", name: "Retaining Walls" },
        ],
        "Buildings and Factories": [
          { value: "1", name: "Residential" },
          { value: "2", name: "Commercial" },
          { value: "3", name: "Factories/Industrial Foundation" },
          { value: "4", name: "High Rise" },
        ],
        "Power Projects": [
          { value: "1", name: "Hydel" },
          { value: "2", name: "Thermal" },
          { value: "3", name: "Nuclear" },
          { value: "4", name: "Transmission Line and Distribution" },
          { value: "5", name: "Renewable - Solar" },
          { value: "6", name: "Renewable - Windmill Foundations" },
        ],
        "Pre-cast": [{ value: "1", name: "Slabs/Kerb Stones" }],
      },
    };
  },
  computed: {
    perDayRequirement() {
      if (
        this.concreteRequirement != "" &&
        this.concretingDurationInMonths != ""
      ) {
        return `
        ${this.concreteRequirement / (this.concretingDurationInMonths * 25)}
      `;
      } else return `0`;
    },
    perMonthRequirement() {
      if (
        this.concreteRequirement != "" &&
        this.concretingDurationInMonths != ""
      ) {
        return `
        ${this.concreteRequirement / this.concretingDurationInMonths}
      `;
      } else return `0`;
    },
    batchingPlantAverageRequirement() {
      var lookupDataSource = this.computeData();
      var lookupData = [];
      var indexLookupData = [];
      for (var i = 0; i < lookupDataSource.length; i++) {
        lookupData.push(lookupDataSource[i][3]);
        indexLookupData.push(lookupDataSource[i][0]);
      }
      if (this.perDayResult < lookupData[0]) {
        return indexLookupData[0];
      } else {
        var matchResult = this.customMatch(this.perDayResult, lookupData, 1);
        var lookupResult = this.customLookup(this.perDayResult, lookupData);
        var calculation = this.perDayResult - 0.2 * this.perDayResult;
        var rowNumber = matchResult + (lookupResult < calculation);
        var indexResult = this.customIndex(indexLookupData, rowNumber);
        if (indexResult == "4") return "0";
        else return indexResult;
      }
    },
    batchingPlantPeakRequirement() {
      var lookupDataSource = this.computeData();
      var lookupData = [];
      var indexLookupData = [];
      for (var i = 0; i < lookupDataSource.length; i++) {
        lookupData.push(lookupDataSource[i][4]);
        indexLookupData.push(lookupDataSource[i][0]);
      }
      if (this.peakConcreteRequirement < lookupData[0]) {
        return indexLookupData[0];
      } else {
        var calculation =
          this.peakConcreteRequirement - 0.2 * this.peakConcreteRequirement;
        var lookupResult = this.customLookup(
          this.peakConcreteRequirement,
          lookupData
        );
        var matchResult = this.customMatch(
          this.peakConcreteRequirement,
          lookupData,
          1
        );
        var rowNumber = matchResult + (lookupResult < calculation);
        var indexResult = this.customIndex(indexLookupData, rowNumber);
        if (indexResult == "4") return "0";
        else return indexResult;
      }
    },
    transitMixerAverageRequirement() {
      var lookupTable = this.computeData();
      var vLookupResult = this.customVlookup(
        this.batchingPlantAverageRequirement,
        lookupTable,
        7,
        0
      );
      if (vLookupResult == "7") return "0";
      else return vLookupResult;
    },
    transitMixerPeakRequirement() {
      var lookupTable = this.computeData();
      var vLookupResult = this.customVlookup(
        this.batchingPlantPeakRequirement,
        lookupTable,
        7,
        0
      );
      if (vLookupResult == "7") return "0";
      else return vLookupResult;
    },
  },
  mounted() {},
  methods: {
    dataArray: function (machine, mins) {
      var tmFillingInMins = 7 / mins + 1;
      var hours = mins * 60 - 60 / tmFillingInMins;
      var day = hours * this.averageWorkingHours;
      var peakDay = hours * this.workingHrsForPeakConcrete;
      var tmRequirement = this.travelDisachargeTimeInMins / tmFillingInMins + 1;
      var resultArray = [
        machine,
        mins,
        hours,
        day,
        peakDay,
        tmFillingInMins,
        tmRequirement,
      ];
      return resultArray;
    },
    computeData() {
      this.averageWorkingDaysPerMonth = 25;
      this.concretingDaysPerMonth = 18;
      this.travelDisachargeTimeInMins =
        Number(this.traverseTime) * 2 + Number(this.avgPlacingTime);
      var machine = [20, 30, 60, 75, 120];
      var min = [0.33, 0.5, 1, 1.5, 2];
      var lookupTable = [];
      var i;
      for (i = 0; i < machine.length; i++) {
        lookupTable.push(this.dataArray(machine[i], min[i]));
      }
      return lookupTable;
    },
    getResults() {
      if (
        this.customerName != "" &&
        this.concreteRequirement != "" &&
        this.peakConcreteRequirement != "" &&
        this.traverseTime != "" &&
        this.avgPlacingTime != "" &&
        this.projectDurationInMonths != "" &&
        this.concretingDurationInMonths != "" &&
        this.averageWorkingHours != "" &&
        this.workingHrsForPeakConcrete != ""
      ) {
        this.computeData();
        this.perDayResult = this.perDayRequirement;
        this.perMonthResult = this.perMonthRequirement;
        this.batchingPlantAverageRequirementResult = this.batchingPlantAverageRequirement;
        this.batchingPlantPeakRequirementResult = this.batchingPlantPeakRequirement;
        this.transitMixerAverageRequirementResult = this.transitMixerAverageRequirement;
        this.transitMixerPeakRequirementResult = this.transitMixerPeakRequirement;
        this.isHidden = false;
      }
      if (this.customerName == "") {
        this.customerNameError = "This field is required";
      }
      if (this.concreteRequirement == "") {
        this.concreteRequirementError = "This field is required";
      }
      if (this.peakConcreteRequirement == "") {
        this.peakConcreteRequirementError = "This field is required";
      }
      if (this.traverseTime == "") {
        this.traverseTimeError = "This field is required";
      }
      if (this.avgPlacingTime == "") {
        this.avgPlacingTimeError = "This field is required";
      }
      if (this.projectDurationInMonths == "") {
        this.projectDurationInMonthsError = "This field is required";
      }
      if (this.concretingDurationInMonths == "") {
        this.concretingDurationInMonthsError = "This field is required";
      }
      if (this.averageWorkingHours == "") {
        this.averageWorkingHoursError = "This field is required";
      }
      if (this.workingHrsForPeakConcrete == "") {
        this.workingHrsForPeakConcreteError = "This field is required";
      }
    },
    customMatch: function (lookupValue, lookupArray, matchType) {
      if (!lookupValue && !lookupArray) {
        return 7;
      }
      if (arguments.length === 2) {
        matchType = 1;
      }
      if (!(lookupArray instanceof Array)) {
        return 7;
      }
      if (matchType !== -1 && matchType !== 0 && matchType !== 1) {
        return 7;
      }
      var index;
      var indexValue;
      for (var idx = 0; idx < lookupArray.length; idx++) {
        if (matchType === 1) {
          if (lookupArray[idx] === lookupValue) {
            return idx + 1;
          } else if (lookupArray[idx] < lookupValue) {
            if (!indexValue) {
              index = idx + 1;
              indexValue = lookupArray[idx];
            } else if (lookupArray[idx] > indexValue) {
              index = idx + 1;
              indexValue = lookupArray[idx];
            }
          }
        } else if (matchType === 0) {
          if (lookupArray[idx] === lookupValue) {
            return idx + 1;
          }
        } else if (matchType === -1) {
          if (lookupArray[idx] === lookupValue) {
            return idx + 1;
          } else if (lookupArray[idx] > lookupValue) {
            if (!indexValue) {
              index = idx + 1;
              indexValue = lookupArray[idx];
            } else if (lookupArray[idx] < indexValue) {
              index = idx + 1;
              indexValue = lookupArray[idx];
            }
          }
        }
      }
      return index ? index : 7;
    },
    customLookup: function (lookupValue, lookupArray) {
      if (!lookupValue && !lookupArray) {
        return 7;
      }
      if (!(lookupArray instanceof Array)) {
        return 7;
      }
      var indexValue;
      for (var idx = 0; idx < lookupArray.length; idx++) {
        if (lookupArray[idx] === lookupValue) {
          return lookupValue;
        } else if (lookupArray[idx] < lookupValue) {
          if (!indexValue) {
            indexValue = lookupArray[idx];
          } else if (lookupArray[idx] > indexValue) {
            indexValue = lookupArray[idx];
          }
        }
      }
      return indexValue ? indexValue : 7;
    },
    customIndex: function (
      cellRange,
      rowNumber //cellRange, rowNumber, columnNumber
    ) {
      // columnNumber = columnNumber ? columnNumber : 1;
      rowNumber = rowNumber ? rowNumber : 1;
      if (rowNumber <= cellRange.length) {
        // if (columnNumber <= cellRange[rowNumber - 1].length) {
        return cellRange[rowNumber - 1]; //cellRange[rowNumber - 1][columnNumber - 1]
        // }
      }
      return 4;
    },
    customVlookup: function (needle, table, index, rangeLookup) {
      if (!needle || !table || !index) {
        return 7;
      }
      rangeLookup = !(rangeLookup === 0 || rangeLookup === false);
      var result = 7;
      for (var i = 0; i < table.length; i++) {
        var row = table[i];
        if (row[0] === needle) {
          result = index < row.length + 1 ? row[index - 1] : 4;
          break;
        } else if (
          (rangeLookup && row[0] <= needle) ||
          (rangeLookup &&
            typeof row[0] === "string" &&
            row[0].localeCompare(needle) < 0)
        ) {
          result = index < row.length + 1 ? row[index - 1] : 4;
        }
      }
      return result;
    },
    // fetchDataFromServer() {
    //   var data_cache_key = "AJAX";
    //   if (this.Online) {
    //     var url = "";
    //     axios.get(url).then((response) => {
    //       this.$offlineStorage.set(data_cache_key, response.data);
    //     });
    //   } else {
    //     var data;
    //     if (this.$offlineStorage.get(data_cache_key) == null) data = {};
    //     else data = this.$offlineStorage.get(data_cache_key);
    //   }
    // },
  },
};
</script>
