<template>
  <calculator/>
</template>

<script>
import Calculator from '../components/Calculator.vue'

export default {
  name: 'Home',
  components: { Calculator }
}
</script>
